import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Sidebar from "../../components/homeMenu/sideBar"
import ProjectsBanner from '../../components/ProjectsBanner'
import ProjectsInfo from '../../components/ProjectsInfo'
import ProjectsFeatures from '../../components/ProjectsFeatures'
import ProjectsMap from '../../components/ProjectsMap'
import ProjectsText from '../../components/ProjectsText'
import ProjectsRelated from '../../components/ProjectsRelated'
import Form from "../../components/FormCasa"
import { connect } from 'react-redux'
import { toggleWhiteSidebar } from "../../state/app"

const SpotPage = ({ dispatch, location }) => {
	const { t } = useTranslation()

	useEffect(() => {
		dispatch(toggleWhiteSidebar(false))
	}, [dispatch])

	var dataGlobal = t("global", { returnObjects: true })
    var dataProjetos = t("projetos", { returnObjects: true })

	return (
		<Layout
			data={dataGlobal}
			hideForm
            location={location}
		>
			<Seo title="Spot" />
			<Sidebar
				content={dataGlobal.sidebar}
				projetosPortfolio={t("portfolio", { returnObjects: true }).projetos}
				projetosVenda={t("projetosVenda", { returnObjects: true }).projetos}
			/>
			<ProjectsBanner
				data={dataProjetos.projects.spot.menu}
				slider={dataProjetos.projects.spot.slider}
			/>
			<ProjectsInfo
				data={dataProjetos.projects.spot.info}
			/>
			<ProjectsText
				data={dataProjetos.projects.spot.info}
			/>
			<ProjectsFeatures
				data={dataProjetos.projects.spot.info.features}
			/>
			<ProjectsMap
				data={dataProjetos.projects.spot.map}
				snazzyLink='https://snazzymaps.com/embed/364690'
			/>
			<Form
				data={dataGlobal.form}
				white
                msgContent={dataGlobal.msgMarkPhone}
			/>
			<ProjectsRelated
                data={dataProjetos.cards}
                projectId={9}
            />
		</Layout>
	)
}

export default connect()(SpotPage)

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["projetos", "global", "projetosVenda", "portfolio"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`
